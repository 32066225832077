import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default class Template extends React.Component {



    render() {
        const {
            data: {
                allMarkdownRemark: { edges },
            },
        } = this.props;

        return (
            <Layout>
                <SEO title="Site Map" />
                <ul >
                    {edges.map(
                        ({
                            node: {
                                id,
                                frontmatter: {
                                    categoryName,
                                    path,
                                },
                            },
                        }) => (
                                <div key={id}>
                                    <Link to={path} >
                                        {categoryName}
                                    </Link>
                                    <br />
                                </div>
                            )
                    )}
                </ul>
            </Layout>
        )
    }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark{
      edges {
        node {
          id
          frontmatter {
            categoryName
            path
          }
        }
      }
    }
  }
`